import gql from 'graphql-tag';

export const getLandingPageContent = gql`
    query LandingPage {
        allCmsLoyaltyWebPlusLanding {
            hero {
                image {
                    src
                    alt
                    webpSrc2x
                    webpSrc1x
                    jpegSrc2x
                    jpegSrc1x
                }
                loggedOutHeroContent {
                    image {
                        src
                        alt
                        webpSrc2x
                        webpSrc1x
                        jpegSrc2x
                        jpegSrc1x
                    }
                    tagline
                    minorTagline
                    additionalText
                    actionButton {
                        text
                        destination
                    }
                }
                loggedInMemberHeroContent {
                    image {
                        src
                        alt
                        webpSrc2x
                        webpSrc1x
                        jpegSrc2x
                        jpegSrc1x
                    }
                    tagline
                    minorTagline
                    additionalText
                    actionButton {
                        text
                        destination
                    }
                }
            }
            imageBanner {
              title,
              desktopImageUrl,
              tabletImageUrl,
              mobileImageUrl,
              destinationUrl,
              imageAltText
            }
            membershipBenefitCards {
                heading
                contentRaw
                savingsText
                savingsSubText
                actionLinkText
                actionLinkFunction
                actionLinkDestination
                testimonialContentRaw
                testimonialName
            }
            membershipBenefitListHeader {
                heading
                contentRaw
                savingsText
                savingsSubText
            }
            membershipBenefitVideoCard {
                videoUrl
                testimonialContentRaw
                testimonialName
            }
            membershipQuestionsSection {
                heading
                subHeading
                links {
                    text
                    destination
                }
                employeeImage {
                    src
                    alt
                    webpSrc2x
                    webpSrc1x
                    jpegSrc2x
                    jpegSrc1x
                }
            }
            nonPlusMemberCallToActionSection {
                heading
                subHeading
                text
                actionButtons {
                    text
                    function
                    destination
                    primary
                }
                image {
                    src
                    alt
                    webpSrc2x
                    webpSrc1x
                    jpegSrc2x
                    jpegSrc1x
                }
            }
            conditionalPerksBannerForLoggedOutOrNotMember {
                banners {
                    ... on CmsCarouselHeroBanner {
                        backgroundColor {
                            hex
                        }
                        bodyRaw
                        button {
                            href
                            buttonText
                            testID
                        }
                        headline
                        desktopImage {
                            alt
                            imageUrl
                        }
                        textColor {
                            hex
                        }
                        title
                        subtitle
                        linkUrl
                    }
                }
                sideButtonConfig {
                    gradientColor {
                        darkColor {
                            hex
                        }
                        lightColor {
                            hex
                        }
                    }
                }
            }
            plusMemberCallToActionSection {
                heading
                subHeading
                text
                actionButtons {
                    text
                    function
                    destination
                    primary
                }
                image {
                    src
                    alt
                    webpSrc2x
                    webpSrc1x
                    jpegSrc2x
                    jpegSrc1x
                }
            }
            footerContentRaw
        }
    }
`;

export const getPharmacyPageContent = gql`
    query PharmacyPage {
        allCmsLoyaltyWebPlusPharmacy {
            hero {
                image {
                    src
                    alt
                    webpSrc2x
                    webpSrc1x
                    jpegSrc2x
                    jpegSrc1x
                }          
                tabletImage {
                    src
                    alt
                    webpSrc2x
                    webpSrc1x
                    jpegSrc2x
                    jpegSrc1x
                }    
                taglineImage {
                    src
                    alt
                    webpSrc2x
                    webpSrc1x
                    jpegSrc2x
                    jpegSrc1x
                }     
                mobileTaglineImage {
                    src
                    alt
                    webpSrc2x
                    webpSrc1x
                    jpegSrc2x
                    jpegSrc1x
                }
                tagline
                minorTagline
                savingsText
                savingsSubText
                savingsDisclaimer
            }
            nonPlusMemberCallToActionSection {
                heading
                subHeading
                text
                actionButtons {
                    text
                    function
                    destination
                    primary
                }
                image {
                    src
                    alt
                    webpSrc2x
                    webpSrc1x
                    jpegSrc2x
                    jpegSrc1x
                }
            }
            plusMemberCallToActionSection {
                heading
                subHeading
                text
                actionButtons {
                    text
                    function
                    destination
                    primary
                }
                image {
                    src
                    alt
                    webpSrc2x
                    webpSrc1x
                    jpegSrc2x
                    jpegSrc1x
                }
            }
        }
    }
`;
