import styled from 'styled-components';
import {spacing, grey, breakpoints} from '@hy-vee/themes';

export const StyledCouponImage = styled.div`
    min-width: 200px;
    width: 200px;
    height: 200px;
    @media (max-width: ${breakpoints.extraSmall}) {
        margin: auto;
    }
    @media (min-width: ${breakpoints.extraSmall}) {
        margin-right: ${spacing.extraLarge}
    }
`;

export const StyledBonusPerkContent = styled.div`
    @media (max-width: ${breakpoints.extraSmall}) {
        margin-top: ${spacing.large};
    }
`;

const StyledH2 = styled.h2`
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: ${spacing.extraSmall};
`;

const MainContent = styled.p`
    font-size: 16px;
    line-height: 22.5px;
    margin-bottom: ${spacing.extraSmall};
`;

const SubContent = styled.p`
    color: ${grey[400]};
    font-size: 14px;
    line-height: 19.6px;
`;

const StyledHr = styled.hr`
    border-top: 1px solid ${grey[300]};
    margin: ${spacing.large} 0;
`;

const StyledBonusPerkModal = styled.div`
    min-height: 250px;
    margin-top: ${spacing.medium};
`;

const StyledBonusPerkDetailModal = styled.div`
    display: flex;
    flex-direction: row;
    
    @media (max-width: ${breakpoints.extraSmall}) {
        flex-direction: column;
    }
`;

const BonusPerkDetailModal = ({bonusPerkDetails}) => {
    const {header, imageUrl, subHeader, details} = bonusPerkDetails;

    return (
        <StyledBonusPerkModal>
            <StyledBonusPerkDetailModal>
                <StyledCouponImage>
                    <img
                        alt={header}
                        data-testid="monthly-bonus-perk-image"
                        src={imageUrl}
                    />
                </StyledCouponImage>
                <StyledBonusPerkContent>
                    <StyledH2>{header}</StyledH2>
                    <MainContent>{subHeader}</MainContent>
                </StyledBonusPerkContent>
            </StyledBonusPerkDetailModal>
            {details &&
                <>
                    <StyledHr />
                    <SubContent>{details}</SubContent>
                </>}
        </StyledBonusPerkModal>
    );
};

export default BonusPerkDetailModal;
