import gql from 'graphql-tag';

const bonusPerksFragment = gql`
    fragment IBonusPerk on bonusPerk {
      currentAmount
      details
      displayType
      endDate
      header
      imageUrl
      requiredAmount
      startDate
      subHeader
      bonusPerkId
      rewardType
      status
      completedText
      completedDate
      redemptionText
      redemptionDetails
      numberOfRedemptions
      repeatable
      completedDetails
      displayApp
      isLifestyleSegment
    }
`;

export const bonusPerksQuery = gql`
    ${bonusPerksFragment}
    query BonusPerksQuery($fuelSaverCardUuid: String!, $displayApp: BonusPerkDisplayApp) {
      bonusPerks(fuelSaverCardUuid: $fuelSaverCardUuid, displayApp: $displayApp) {
        ...IBonusPerk
      }
    }
`;
