import {useRef} from 'react';

import {useFuelSaverCard} from '../../hooks/useFuelSaverCard';

import BonusPerksSliderCard from './bonus-perks-slider-card';
import BonusPerksScrollButton from './bonus-perk-slider-scroll-button';
import styles from './bonus-perks-slider.module.css';

const BonusPerksSlider = ({bonusPerks, className, setIsBonusPerkError, setBonusPerkOfferDetails}) => {
    const sliderRef = useRef(null);
    const fuelSaverCard = useFuelSaverCard();

    return (
        <div
            className={className}
            data-testid="bonus-perk-slider"
        >
            <div
                className={styles.sliderContent}
                ref={sliderRef}
            >
                <BonusPerksScrollButton
                    direction="left"
                    parentRef={sliderRef}
                />
                <BonusPerksScrollButton
                    direction="right"
                    parentRef={sliderRef}
                />
                {bonusPerks.map((bonusPerk) => <BonusPerksSliderCard
                    bonusPerkId={bonusPerk.bonusPerkId}
                    details={bonusPerk.details}
                    endDate={bonusPerk.endDate}
                    fuelSaverCardUuid={fuelSaverCard?.fuelSaverCard?.fuelSaverCardUuid}
                    header={bonusPerk.header}
                    imageUrl={bonusPerk.imageUrl}
                    key={bonusPerk.bonusPerkId}
                    setBonusPerkOfferDetails={setBonusPerkOfferDetails}
                    setIsBonusPerkError={setIsBonusPerkError}
                    startDate={bonusPerk.startDate}
                    status={bonusPerk.status}
                    subHeader={bonusPerk.subHeader}
                />,
                )}
            </div>
        </div>
    );
};

export default BonusPerksSlider;
