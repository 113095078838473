import {Button} from '@hy-vee/design-system';

import {NextWrappedLink} from '../../next-wrapped-link';

import styles from './non-plus-member-banner.module.css';

const NonPlusMemberBanner = ({content}) => {
    if (!content) {
        return null;
    }

    const {
        banners,
        sideButtonConfig,
    } = content;
    const banner = banners?.[0];

    if (!banner) {
        return null;
    }

    const hasBody = banner.bodyRaw?.some((body) => body.children?.some((child) => child?.text?.trim())) || false;
    const hasButtonOrLink = banner.button?.buttonText && banner.button?.href || banner.linkText && banner.linkUrl;
    const imageHasHrefAndAlt = Boolean(banner.desktopImage?.alt && banner.desktopImage?.imageUrl);

    return (
        <div
            className={styles.bannerContainer}
            data-testid="non-plus-member-banner-container"
        >
            {
                Boolean(banner.textColor) &&
                    <div
                        className={styles.textContainer}
                        style={{
                            background: `linear-gradient(to right bottom, ${sideButtonConfig?.gradientColor?.darkColor?.hex || '#242729'}, ${sideButtonConfig?.gradientColor?.lightColor?.hex || '#3F3F3F'})`,
                            color: banner.textColor?.hex,
                        }}
                    >
                        <div
                            className={styles.textContent}
                        >
                            {
                                Boolean(banner.title) &&
                                    <div
                                        className={styles.bannerTitle}
                                        data-testid="non-plus-member-banner-title"
                                    >
                                        {banner.title}
                                    </div>
                            }
                            {
                                Boolean(banner.headline) &&
                                    <div
                                        className={styles.bannerSubtitle}
                                        data-testid="non-plus-member-banner-subtitle"
                                    >
                                        {banner.subtitle || 'Clip everyday coupons in your PERKS Hub to take advantage of an average of'}
                                        {' '}
                                        <span
                                            className={styles.bannerHeadline}
                                            data-testid="non-plus-member-banner-headline"
                                        >
                                            {banner.headline}
                                        </span>
                                    </div>
                            }
                            {
                                hasBody &&
                                    <div
                                        className={styles.body}
                                        data-testid="non-plus-member-banner-body"
                                    >
                                        {banner.bodyRaw?.[0]?.children?.[0]?.text}
                                    </div>
                            }
                            {
                                hasButtonOrLink &&
                                    <Button
                                        className={styles.linkContainer}
                                        data-testid="non-plus-member-banner-button"
                                    >
                                        <NextWrappedLink
                                            className={styles.link}
                                            data-testid="non-plus-member-banner-link"
                                            href={banner.button.href}
                                            style={{
                                                color: banner.textColor?.hex,
                                            }}
                                        >
                                            {banner.button.buttonText}
                                        </NextWrappedLink>
                                    </Button>
                            }
                        </div>
                    </div>
            }
            {
                imageHasHrefAndAlt &&
                    <div
                        className={styles.imageContainer}
                    >
                        <img
                            alt={banner.desktopImage.alt}
                            className={styles.image}
                            data-testid="non-plus-member-banner-image"
                            src={banner.desktopImage.imageUrl}
                        />
                    </div>
            }
        </div>
    );
};

export default NonPlusMemberBanner;
