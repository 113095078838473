import {differenceInDays, format} from 'date-fns';

export const formatDate = (date) => format(new Date(date ? date : Date.now()), 'MM/dd/yyyy');

export const formatDateMMdd = (date) => format(new Date(date ? date : Date.now()), 'MM/dd');

export const calculateDaysRemaining = (date) => differenceInDays(new Date(date), new Date());

export const getMembershipDuration = (durationInterval, durationUnit) => {
    if (durationInterval === 1 && durationUnit === 'YEARS') {
        return 'annually';
    } else if (durationInterval === 1 && durationUnit === 'MONTHS') {
        return 'monthly';
    }

    if (durationUnit) {
        return `every ${durationInterval} ${durationUnit.toLowerCase()}`;
    }

    return 'automatically';
};

export const getLatestItemByDate = (collection, datePropertyName) =>
    collection.sort((a, b) => new Date(b[datePropertyName]) - new Date(a[datePropertyName]))[0];
