import {useQuery, gql} from '@apollo/client';

export const useFuelSaverCard = () => {
    const {data, loading} = useQuery(gql`
        query FetchCustomerFuelSaverQuery {
            myCustomer {
                customerUuid
                firstName
                lastName
                fuelSaverCard {
                    fuelSaverCardUuid
                    fuelSaverCardNumber
                    firstName
                    lastName
                    addressUuid
                    phoneNumber
                    dateOfBirth
                }
            }
        }
    `, {fetchPolicy: 'no-cache'});

    const myCustomer = data?.myCustomer;
    const fuelSaverCard = myCustomer?.fuelSaverCard;

    return {
        fuelSaverCard,
        loading,
        myCustomer,
    };
};
