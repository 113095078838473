import BlockContent from '@sanity/block-content-to-react';

import styles from './bonus-perk-list.module.css';

const BonusPerkHeader = ({bonusPerkListHeader}) => (
    <div className={styles.bonusPerkListHeaderCard}>
        <div className={styles.bonusPerkListHeader}>
            <div>
                <img
                    alt="Plus Logo"
                    src="https://808dde4744a73b71b1e3-8812790c7feaac6b6f6577276d42f086.ssl.cf2.rackcdn.com/Plus-Large.svg"
                />
            </div>
            <div>
                <h2>{bonusPerkListHeader.heading}</h2>
                <div className={styles.content}>
                    <BlockContent blocks={bonusPerkListHeader.contentRaw} />
                </div>
                <p className={styles.savingsText}>{bonusPerkListHeader.savingsText}</p>
                <p className={styles.savingsSubText}>{bonusPerkListHeader.savingsSubText}</p>
            </div>
        </div>
    </div>
);

export default BonusPerkHeader;
