// eslint-disable-next-line no-unused-vars
import React from 'react';
import Link from 'next/link';

export const NextWrappedLink = ({href, children, ...props}) => (
    <Link href={href}>
        {/* eslint-disable-next-line react/jsx-no-target-blank */}
        <a
            data-testid="next-link"
            {...props}>
            {children}
        </a>
    </Link>
);
