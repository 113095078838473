import {useState} from 'react';
import {SecondaryButton, TextLinkButton} from '@hy-vee/design-system';
import {grey, sizing, colors, red} from '@hy-vee/themes';
import styled from 'styled-components';

import {formatDateMMdd} from '../../utils/view-helpers/date-time-view-helpers';
import {graphqlClient} from '../../graphql/graphql-client';
import {activateBonusPerkMutation} from '../../graphql/mutations/bonus-perk-mutations';
import * as bonusPerkStatuses from '../../enums/bonus-perk-statuses';

import styles from './bonus-perks-slider.module.css';

const StyledP = styled.p`
  font-weight: 700;
  font-size: ${sizing[14]};
  color: ${grey[500]};
`;

const StyledButtonActivated = styled(SecondaryButton)`
    background-color: ${colors.grey[300]};
    color: ${colors.grey[500]};
    border: none;
`;

const StyledButtonUnactivated = styled(SecondaryButton)`
    background-color: #e0241c;
    color: white;
    border: none;
    &:hover {
        color: ${red.darkRed};
        background-color: ${red.lightRed};
    }
`;

const CLIP_PERK_DEFAULT_ERROR = 'Error: Unable to clip coupon at this time.';

const BonusPerksSliderCard = ({startDate, endDate, bonusPerkId, fuelSaverCardUuid, header, imageUrl, subHeader, details, status, setIsBonusPerkError, setBonusPerkOfferDetails}) => {
    const [isActivated, setIsActivated] = useState(false);

    const formattedStartDate = formatDateMMdd(startDate);
    const formattedEndDate = formatDateMMdd(endDate);

    const handleActivation = async () => {
        try {
            await graphqlClient().mutate({
                fetchPolicy: 'network-only',
                mutation: activateBonusPerkMutation,
                variables: {
                    bonusPerkId,
                    fuelSaverCardUuid,
                },
            });

            setIsActivated(true);
        } catch {
            setIsBonusPerkError(CLIP_PERK_DEFAULT_ERROR);
        }
    };

    return (
        <article
            className={styles.sliderCard}
            data-testid="bonus-perk-slider-card"
            id={bonusPerkId}
        >
            <img
                alt={header}
                data-testid="monthly-bonus-perk-image"
                src={imageUrl}
            />
            <section>
                <StyledP data-testid="bonus-perk-active-dates">
                    {`Valid ${formattedStartDate} - ${formattedEndDate}`}
                </StyledP>
                <h2 data-testid="monthly-bonus-perk-header">{header}</h2>
                <div>
                    <TextLinkButton
                        data-testid="offer-details-button"
                        onClick={() => setBonusPerkOfferDetails({
                            details,
                            header,
                            imageUrl,
                            subHeader,
                        })}
                        size="small"
                    >
                        {'Offer Details'}
                    </TextLinkButton>
                </div>
            </section>
            {
                status === bonusPerkStatuses.ACTIVE || isActivated ?
                    <StyledButtonActivated
                        data-testid="activated-offer-button"
                        disabled>
                        {'Activated'}
                    </StyledButtonActivated> :
                    <StyledButtonUnactivated
                        data-testid="activate-offer-button"
                        onClick={handleActivation}>
                        {'Activate'}
                    </StyledButtonUnactivated>
            }
        </article>
    );
};

export default BonusPerksSliderCard;
