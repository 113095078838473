import {useEffect, useState} from 'react';
import {useSmoothScroll} from 'react-smooth-scroll-hook';
import {CaretLeftIcon, CaretRightIcon} from '@hy-vee/icons';
import {LinkButton} from '@hy-vee/web-core/lib/components/button';

import styles from './bonus-perks-slider.module.css';

const BonusPerksSliderScrollButton = ({direction, parentRef}) => {
    const scrollModifier = 0.85;
    const [scrollWidth, setScrollWidth] = useState(0);
    const {scrollTo, reachedTop: reachedLeft, reachedBottom: reachedRight} = useSmoothScroll({
        direction: 'x',
        ref: parentRef,
        speed: 50,
        threshold: 5,
    });
    const buttonOptions = {
        left: {
            className: styles.leftScroll,
            icon: <CaretLeftIcon
                color="white"
                data-testid="left-caret-icon"
            />,
            onClick: () => scrollTo(scrollWidth * -1),
            shouldDisplay: scrollWidth && !reachedLeft,
        },
        right: {
            className: styles.rightScroll,
            icon: <CaretRightIcon
                color="white"
                data-testid="right-caret-icon"
            />,
            onClick: () => scrollTo(scrollWidth),
            shouldDisplay: scrollWidth && !reachedRight,
        },
    };

    useEffect(() => {
        setScrollWidth(Math.floor(parentRef.current?.clientWidth * scrollModifier));
    }, [parentRef]);

    const options = buttonOptions[direction];

    return options?.shouldDisplay ? (
        <LinkButton
            className={options.className}
            data-testid={`${direction}-slider-scroll-button`}
            onClick={options.onClick}
        >
            {options.icon}
        </LinkButton>
    ) : null;
};

export default BonusPerksSliderScrollButton;
