import {Query} from '@apollo/client/react/components';

import {bonusPerksQuery} from '../../../graphql/queries/bonus-perk-queries';
import BonusPerksSlider from '../../bonus-perks-slider';

import styles from './bonus-perk-list.module.css';

const ActiveBonusPerksQuery = ({fuelSaverCardUuid, setIsBonusPerkError, setBonusPerkOfferDetails}) => (
    <Query
        query={bonusPerksQuery}
        variables={{
            fuelSaverCardUuid,
        }}
    >
        {({loading, data}) => {
            const bonusPerks = data?.bonusPerks ? [
                ...data.bonusPerks,
            ] : [];

            bonusPerks.sort((a, b) => a.displayOrder - b.displayOrder);
            const lifestyleSegmentBonusPerks = bonusPerks.filter((bonusPerk) => bonusPerk.isLifestyleSegment);

            return (
            // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                    {!loading &&
                        <BonusPerksSlider
                            bonusPerks={lifestyleSegmentBonusPerks}
                            className={styles.bonusPerksSliderContainer}
                            setBonusPerkOfferDetails={setBonusPerkOfferDetails}
                            setIsBonusPerkError={setIsBonusPerkError}
                        />}
                </>
            );
        }}
    </Query>
);

export default ActiveBonusPerksQuery;
