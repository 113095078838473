import {useState} from 'react';

import BonusPerkHeader from './header';
import ActiveBonusPerksQuery from './active-bonus-perk-query';
import styles from './bonus-perk-list.module.css';
import {BonusPerkListAlert} from './bonus-perk-list-alert';

const BonusPerkList = ({bonusPerkListHeader, fuelSaverCardUuid, setBonusPerkOfferDetails}) => {
    const [isBonusPerkError, setIsBonusPerkError] = useState('');

    return (
        <>
            <div className={styles.bonusPerkList}>
                <BonusPerkHeader bonusPerkListHeader={bonusPerkListHeader} />
                <ActiveBonusPerksQuery
                    fuelSaverCardUuid={fuelSaverCardUuid}
                    setBonusPerkOfferDetails={setBonusPerkOfferDetails}
                    setIsBonusPerkError={setIsBonusPerkError} />
            </div>
            {isBonusPerkError && <BonusPerkListAlert
                isBonusPerkError={isBonusPerkError}
                setIsBonusPerkError={setIsBonusPerkError} />}
        </>
    );
};

export default BonusPerkList;
